import dynamic from 'next/dynamic'
import { Fragment } from 'react'
import Head from 'next/head'

const DynamicLibraryLoader = dynamic(
  () => import('@utils/DynamicLibraryLoader'),
  { ssr: false }
)

export default function List() {
  return (
    <Fragment>
      <Head>
        <title>403 Forbidden</title>
      </Head>

      <DynamicLibraryLoader
        component="EmptyContent"
        props={{
          iconType: 'fas fa-user-times',
          title: 'Authorization expired',
          text: 'Please renew your authorization by visiting wildcat\'s quotes and click on review scrape',
          actions: (
            <button className="cgt-button cgt-button--primary" onClick={() => window.location.replace('https://wildcat.cgtrader.com/quotes')}>
              Go to Wildcat
            </button>
          )
        }}
      />
    </Fragment>
  )
}
